require('./BRC')
require('./ClonedPair')
require('./HiddenPair')
require('./HiddenQuadruple')
require('./HiddenTriple')
require('./import')
require('./index')
require('./Kite')
require('./NakedPair')
require('./NakedQuadruple')
require('./NakedSingle')
require('./NakedTriple')
require('./RBC')
require('./rectangles')
require('./Skyscraper')
require('./Steinbutt')
require('./Swordfish')
require('./ThirdEye')
require('./Versteckter Single')
require('./WWing')
require('./XChain')
require('./XWing')
require('./XYWing')
//eslint-disable-next-line import/no-anonymous-default-export
export default {}
